import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageWko from "../components/imageWko"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
	 <h1>Impressum</h1>
<p>Informationspflicht lt. §5 E-Commerce Gesetz,  <br></br>
§14 Unternehmensgesetzbuch bzw. §63 Gewerbeordnung und Offenlegungspflicht lt. §25 Mediengesetz</p> 
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <ImageWko />
    </div>

 <h2>Verantwortlich für den Inhalt:</h2> 
 <p>Ing. Edwin Bruckner e.U.
 <br></br>

 Franz-Berger-Strasse 3
  <br></br>
 A-3508 Höbenbach
  <br></br>
 E-Mail: 
 <a href="mailto:office@cb1.at">office@cb1.at</a>
  <br></br>
Internet:&nbsp;<a href="http://www.cb1.at">www.cb1.at</a>
</p>
 <h2>Firmenbucheintrag</h2>
 <p><a href="http://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=3671abc0-4c45-429b-ad64-78c215c69f59&amp;StandortID=0">WKO Firmen A–Z</a></p>

 
 <Link to="/">Startseite</Link>
  </Layout>
)

// <a href="mailto:office@cb1.at">office@cb1.at</a>
// Internet:&nbsp;<a href="http://www.cb1.at">www.cb1.at</a></p> 
// <h2>Firmenbucheintrag</h2>
// <p><a href="http://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=3671abc0-4c45-429b-ad64-78c215c69f59&amp;StandortID=0">http://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=3671abc0-4c45-429b-ad64-78c215c69f59&amp;StandortID=0</a></p>

export default SecondPage
